@font-face {
  font-family: "PuntaRegular";   
  src: local("AirbnbCerealLight"),
    url("./punta/Punta Regular.ttf") format("truetype");
}

@font-face {
  font-family: "PuntaBold"; 
  src: local("AirbnbCerealLight"),
    url("./punta/Punta Bold.ttf") format("truetype");
}



