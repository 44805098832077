body {
  margin: 0;
  font-family: 'PuntaRegular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: black;
}
.social:hover{
  animation: animate 1s;
}

@keyframes animate {
  25%{
    transform: scale(0.8,1.3);
  }
  50%{
    transform: scale(1.1,0.8);
  }
  75%{
    transform: scale(0.7,1.2);
  }
}

.project:hover{
  cursor: pointer;
  transition-duration: 0.5s;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
